import React from 'react';
import { Link } from 'react-router-dom';


class Ambition extends React.Component{ 
    
    render(){
    return (
       <div className='container mx-auto text-start mt-5'>
                   <nav aria-label="breadcrumb">
                       <ol className="breadcrumb">
                           <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                           <li className="breadcrumb-item" aria-current="page"><Link to='/essays'>Essays</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Ambition & Agency</li>
                       </ol>
                   </nav>
                   <div className='content-div col-lg-6 col-xl-6'>
                       <span className="d-inline-block" tabindex="0" data-bs-toggle="popover" data-bs-placement="right"data-bs-trigger="hover focus" data-bs-content="7 Jan 2025">
                           <h1 className='heading strong bold'>Ambition & Agency</h1>
                       </span>
                <p></p>           
            
            
          
            <p>
“Ambition means you're motivated to play games that others have already created in the world, while agency means you're driven to play a game of your own.” <a href='https://www.mothfund.com/manifesto' target='_blank'>1</a>
<br /><br/>
It’s a great definition of both agency and ambition.
<br /><br/>
At first glance, it might seem that it’s putting being agentic above ambition. And the self-proclaimed ambitious part of someone might get offended. But this definition helped me put my goals into perspective.



<br /><br/>
I want to make films and build businesses.



<br /><br/>
Now, this might come into the ambition category. It’s a game that’s already played a thousand times.



<br /><br/>
But the agentic question to ask here is: how can you change the rules your way?



<br /><br/>
Older the system, the harder it would be to change the ways it’s done. But it will be as much fun. It will also create things and mold individuals in a way that the world never thought was possible.


<br /><br/>
Another reason it’s great is because it combines the best of both parts. Take something lindy (old game) so you have a higher chance of success. But change it just enough that you create something new in it (your own, new game).


<br /><br/>

The most important, and hard, part here is to manage your own agency. Because prerequisite of an agentic person is that they do not need external stimuli to do a task that is a) hard, b) high value, and c) delays gratification.

<br /><br/>

That answers the “how” question. Another important question to ask is “what”.
<br /><br/>

You need a focused throughput on a regular basis on long enough intervals in the right direction to win. But an agentic person is always conflicted.
<br /><br/>

Your main goal is to build your company.


<br /><br/>
But then when you do something else, you feel like you are taking away something from building your company. For example, when I sit down to write a blog, I feel like I should tell these ideas through characters in my screenplay. Or write another cold email, memo, PRD — anything but this!
<br /><br/>
And now, I have a cognitive dissonance, I cannot do what I have sat down to do. Neither do I have my full heart in the second activity I’ll go on to do.

<br /><br/>

An individual I worked with asked me a wonderful question when I was having second thoughts about working on something, “where is your heart at?”
<br /><br/>


Because you can't give your all to something where your heart is not.
<br /><br/>

If you answer that question honestly, for most of the dilemmas, you’ll have your answer right in front of you clearly.
<br /><br/>

All you need is courage to act on it — and the thing about courage is that it never comes tomorrow.

<br /><br/>



</p>            
<br />
<br />
<br/>
            <p style={{color : "rgba(204, 238, 253, 0.884)"}}>Jan 2025</p>
        </div>
        </div>
    )
}
}
 
export default Ambition;
