import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './components/Home';
import Bookshelf from './components/bookshelf';
import Blogs from './components/Blogs';
import Psych from './components/psych';
import Work from './components/work';
import About from './components/About';
import Memes from './components/all_blogs/memes';
import C_bias from './components/all_blogs/c_bias';
import Stories from './components/all_blogs/stories';
import Curious from './components/all_blogs/meta-curious';
import Freedom from './components/all_blogs/fiction/freedom';
import Privilege from './components/all_blogs/privilege';
import Metaverse from './components/all_blogs/metaverse';
import Friends from './components/all_blogs/friends'
import Music from './components/all_blogs/music'
import Fear from './components/all_blogs/fear';
import Cinema from './components/all_blogs/cinema';
import Anxiety from './components/all_blogs/anxiety';
import Truth from './components/all_blogs/truth';
import Identity from './components/all_blogs/identity';
import Thinking from './components/all_blogs/thinking';
import Morality from './components/all_blogs/morality';
import Poetry from './components/all_blogs/poetry';
import Timeswap from './components/all_blogs/timeswap';
import Solend from './components/all_blogs/solend';
import Write from './components/all_blogs/write';
import Biconomy from './components/all_blogs/fiction/biconomy';
import Hubble from './components/all_blogs/hubble';
import Love from './components/all_blogs/love';
import Superficial from './components/all_blogs/superficial';
import Great from './components/all_blogs/great';
import Language from './components/all_blogs/language';
import Melancholic from './components/all_blogs/melancholic';
import Taste from './components/all_blogs/taste';
import Honor from './components/all_blogs/honor';
import Craft from './components/all_blogs/craft';
import Ambition from './components/all_blogs/ambition';
import Beauty from './components/all_blogs/beauty';






function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path='/bookshelf' element={<Bookshelf/>} />
          <Route path='/essays' element={<Blogs/>} />
          <Route path='/work' element={<Work/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/psych-archives' element={<Psych/>} />
          <Route path='/essays/memes' element={<Memes/>} />
          <Route path='/essays/c_bias' element={<C_bias/>} />
          <Route path='/essays/stories' element={<Stories/>} />
          <Route path='/essays/cinema' element={<Cinema/>} />
          <Route path='/essays/meta-curious' element={<Curious/>} />
          <Route path='/essays/metaverse' element={<Metaverse/>} />
          <Route path='/essays/privilege' element={<Privilege/>} />
          <Route path='/essays/friendships' element={<Friends/>} />
          <Route path='/essays/music' element={<Music/>} />
          <Route path='/essays/fear-and-freedom' element={<Fear/>} />
          <Route path='/essays/anxiety' element={<Anxiety/>} />
          <Route path='/essays/thinking-mold' element={<Thinking/>} />
          <Route path='/essays/morality' element={<Morality/>} />
          <Route path='/essays/truth' element={<Truth/>} />
          <Route path='/essays/write' element={<Write/>} />
          <Route path='/essays/identity' element={<Identity/>} />
          <Route path='/essays/love' element={<Love/>} />
          <Route path='/essays/language' element={<Language/>} />
          <Route path='/essays/superficial' element={<Superficial/>} />
          <Route path='/essays/great' element={<Great/>} />
          <Route path='/essays/melancholic' element={<Melancholic/>} />
          <Route path='/essays/timeswap' element={<Timeswap/>} />
          <Route path='/essays/solend' element={<Solend/>} />
          <Route path='/essays/biconomy' element={<Biconomy/>} />
          <Route path='/essays/hubble' element={<Hubble/>} />
          <Route path='/essays/taste' element={<Taste/>} />
          <Route path='/essays/honor' element={<Honor/>} />
          <Route path='/essays/craft' element={<Craft/>} />
          <Route path='/essays/ambition' element={<Ambition/>} />
          <Route path='/essays/beauty' element={<Beauty/>} />
          <Route path='/fiction/freedom' element={<Freedom/>} />
          <Route path='/dead-poet-society' element={<Poetry/>} />

        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
