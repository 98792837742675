import React from 'react';
import { Link } from 'react-router-dom';

class Beauty extends React.Component {
    render() {
        return (
            <div className='container mx-auto text-start mt-5'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item"><Link to='/essays'>Essays</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Beauty & Patience</li>
                    </ol>
                </nav>
                <div className='content-div col-lg-6 col-xl-6'>
                    <span className="d-inline-block" tabindex="0" data-bs-toggle="popover" data-bs-placement="right" data-bs-trigger="hover focus" data-bs-content="15 Dec 2024">
                        <h1 className='heading strong bold'>Beauty & Patience</h1>
                    </span>


                    <p><br />

                        "I have lived here for 23 years. But I still cannot call this home. Because I feel like I'll have to leave here anytime."
                        <br /><br />
                        All We Imagine As Light by Payal Kapadia starts with this line. The first ever Indian film to win the Grand Prix at Cannes and well deserved so. The quote is a voice-over, in Gujarati, while we see the city of Mumbai in its rawest form. At 5 AM in the morning, the vegetable vendors are unloading their trucks to sit on the roadside. These few hours slot in the morning will be the only time that they will be able to sell today. When you look at them, you just know that none of these people are originally from Mumbai. The film shows that as well, as we hear multiple languages spoken in the first few minutes of the film. Most of the film is in Malayalam, even though it's set in Mumbai.
                        <br /><br />
                        Cities like Mumbai are filled with people who have no one. If they fall, they fall. Sometimes, a stranger finds you and picks you up. But when you are falling, you know no one that you would recognize would hold you in this city. That's also why you would feel a sense of belonging there because when millions of people are in one place, who have no one, they stand for each other. There is a certain unspoken unity in immigrants.
                        <br /><br />
                        The film follows Prabha and other nurses who migrated to Mumbai from Kerala and one older nurse who is from a village in Maharashtra. In the whole movie, Prabha longs for her husband. Who, just like her, went to Germany for better opportunities in life.
                        <br /><br />
                        She has not talked to him for over a year. She is waiting for him with her whole heart. And I was wondering what is it that makes this film so beautiful. The answer might be: waiting.
                        <br /><br />
                        There is beauty in waiting. Patience is a trait of beauty. If you are not patient, you cannot create beauty. Because, simply, you cannot rush beauty.
                        <br /><br />
                        I have found the most beautiful people in life are often ok waiting. The classic folded-hands-in-the-back walk of older men when they wait. The conversations with strangers while you both are waiting in a lobby somewhere. The sound of laughter and weeping in the arrival section of the airport when the wait is finally over. In all the running of ambition, there is a lot of waiting.
                        <br /><br />
                        Great artists are patient. Leonard Cohen wrote over 180 verses for Hallelujah and used only 4. Van Gogh made over 2000 artworks with close to no recognition in his lifetime. Same with Franz Kafka. Tarantino is 61 and he has made only 9 films so far. Each one being better than the other. If any of these artists rushed or did not wait for the right verse, character, color stroke, or paragraph, we might not have Starry Night, Hallelujah, or Inglourious Basterds today.
                        <br /><br />
                        My favorite story is of Ed Sheeran showing his early music and how patient he was to be great at what <a href='https://youtu.be/flkjMuaKYQU' target='_blank'>he</a> does.
                        <br /><br />
                        As I am writing, I realize, that the point behind patience, in its rawest form is looking beyond what everyone sees. For example, if you read or write a lot, your eye is sensitive to a well-written sentence. It icks you when a sentence is badly written. You are furious when you find a mistake or improvement after hitting the publish button. It can be anything that you have spent a lot of time at: chess, films, singing, or even listening to music. After a while, you start understanding intuitively when something is wrong or different. You develop a taste for beauty.
                    </p>
                    <br />
                    <br />
                    <br />
                    <p style={{color: "rgba(204, 238, 253, 0.884)"}}>Dec 2024</p>
                </div>
            </div>
        )
    }
}

export default Beauty;
